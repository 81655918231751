import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Radio, Divider, Form, Input, message, Button } from "antd";

import useJDStore from "../../store/jd.store";

import "./jdCreationForm.scss";

export default function JDCreationForm({ setCurrentView }) {
    const [isEditMode, setIsEditMode] = useState(false);
    const [formKey, setFormKey] = useState(1);
    const [isRequired, setIsRequired] = useState(true);

    const { value } = useParams();
    const { createJd, updateJd, selectedJD, clearJD } = useJDStore();

    useEffect(() => {
        console.log("value", value);
        if (value) {


        } else {

            setIsEditMode(true)
            clearJD()
            jdValues = {}

        }
    }, [value]);

    const handleFormSubmit = (values) => {
        createJd({
            ...values,
            number_of_positions: parseInt(values.number_of_positions),
            experience: parseInt(values.experience),
            notice_period: parseInt(values.notice_period),
            offered_CTC: parseFloat(values.offered_CTC),
            status: "active"
        });
        message.success("Job description created successfully");
        setCurrentView("assignment");
    };

    const handleUpdateFormSubmit = (values) => {
        console.log("called")
        updateJd(
            {
                ...values,
                number_of_positions: parseInt(values.number_of_positions),
                experience: parseInt(values.experience),
                notice_period: parseInt(values.notice_period),
                offered_CTC: parseFloat(values.offered_CTC),
            },
            value
        );
        message.success("Job description updated successfully");
        setCurrentView("assignment");
        setIsEditMode(false);
    };
    useEffect(() => {
        console.log(selectedJD);
        setFormKey((prevKey) => prevKey + 1);
    }, [selectedJD]);

    if (value) {
        var jdValues = {
            hiring_for: selectedJD?.hiring_for,
            title: selectedJD?.title,
            about_company: selectedJD?.about_company,
            client_name: selectedJD?.client_name,
            roles_and_responsibility: selectedJD?.roles_and_responsibility,
            skills: selectedJD?.skills,
            keywords: selectedJD?.keywords,
            soft_skills: selectedJD?.soft_skills,
            education: selectedJD?.education,
            offered_CTC: selectedJD?.offered_CTC,
            creation_date: selectedJD?.creation_date,
            priority: selectedJD?.priority,
            number_of_positions: selectedJD?.number_of_positions,
            valid_until: selectedJD?.valid_until,
            work_location: selectedJD?.work_location,
            work_from_home: selectedJD?.work_from_home,
            notice_period: selectedJD?.notice_period,
            experience: selectedJD?.experience,
            hiring_project_team_name: selectedJD?.hiring_project_team_name,
            hiring_project_team_email_id: selectedJD?.hiring_project_team_email_id,
            hiring_project_group_contact_number:
                selectedJD?.hiring_project_group_contact_number,
        };
    }


    return (
        <div className="jd-creation-form-wrapper">
            <div className="header">
                <h1 className="jd-creation-form-title">Hiring For</h1>
                {!isEditMode && (
                    <Button onClick={() => setIsEditMode(true)} type="primary">
                        Edit JD
                    </Button>
                )}
            </div>
            <Form
                key={formKey}
                className="jd-creation-form"
                name="jd-creation-form"
                layout="vertical"
                autoComplete="off"
                onFinish={value ? handleUpdateFormSubmit : handleFormSubmit}
                initialValues={jdValues}
            >
                <Form.Item
                    required={value ? false : true}
                    className="jd-creation-field-item"
                    name="hiring_for"
                >
                    <Radio.Group
                        disabled={!isEditMode}
                        className="project-type-option-wrapper"
                    >
                        <Radio
                            className="project-option"
                            value="internal-project"
                        >
                            Internal Project
                        </Radio>
                        <Radio
                            className="project-option"
                            value="customer-project"
                        >
                            Customer Project
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                <Divider className="jd-creation-form-divider" />
                <Form.Item
                    required={value ? false : true}
                    className="jd-creation-field-item"
                    label="Job Title"
                    name="title"
                >
                    <Input
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="jd-creation-input"
                    />
                </Form.Item>
                <Form.Item
                    required={value ? false : true}
                    className="jd-creation-field-item"
                    label="Client Name"
                    name="client_name"
                >
                    <Input
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="jd-creation-input"
                    />
                </Form.Item>
                <Form.Item
                    required={value ? false : true}
                    name="about_company"
                    className="jd-creation-field-item"
                    label="About Company"
                >
                    <Input
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="jd-creation-input"
                    />
                </Form.Item>
                <Form.Item
                    required={value ? false : true}
                    name="roles_and_responsibility"
                    className="jd-creation-field-item"
                    label="Role & Responsibility"
                >
                    <Input
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="jd-creation-input"
                    />
                </Form.Item>
                <div className="dual-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="skills"
                        className="jd-creation-field-item"
                        label="Skills Required"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        required={value ? false : true}
                        name="keywords"
                        className="jd-creation-field-item"
                        label="Keywords"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    required={value ? false : true}
                    name="soft_skills"
                    className="jd-creation-field-item"
                    label="Soft Skills Required"
                >
                    <Input
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="jd-creation-input"
                    />
                </Form.Item>
                <div className="dual-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="education"
                        className="jd-creation-field-item"
                        label="Education"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        required={value ? false : true}
                        name="offered_CTC"
                        className="jd-creation-field-item"
                        label="Offered CTC"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="number"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                </div>
                <div className="dual-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="number_of_positions"
                        className="jd-creation-field-item"
                        label="Number of Positions"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="number"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        required={value ? false : true}
                        name="valid_until"
                        className="jd-creation-field-item"
                        label="Valid Until"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                </div>
                <div className="dual-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="work_location"
                        className="jd-creation-field-item"
                        label="Work Location"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        required={isRequired}
                        name="work_from_home"
                        className="jd-creation-field-item"
                        label="Work From Home"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper"
                        >
                            <Radio className="radio-option" value={true}>
                                Yes
                            </Radio>
                            <Radio className="radio-option" value={false}>
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className="dual-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Notice Period"
                        name="notice_period"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="number"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Experience Range"
                        name="experience"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="number"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    name="additional_details"
                    className="jd-creation-field-item"
                    label="Additional details"
                >
                    <Input
                        disabled={!isEditMode}
                        className="jd-creation-input"
                    />
                </Form.Item>
                <div className="dual-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="creation_date"
                        className="jd-creation-field-item"
                        label="JD Creation Date"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        required={value ? false : true}
                        name="priority"
                        className="jd-creation-field-item"
                        label="JD Priority"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    required={value ? false : true}
                    name="hiring_project_team_name"
                    className="jd-creation-field-item"
                    label="Hiring Project Team Name"
                >
                    <Input
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="jd-creation-input"
                    />
                </Form.Item>
                <Form.Item
                    required={value ? false : true}
                    name="hiring_project_team_email_id"
                    className="jd-creation-field-item"
                    label="Hiring Project Team E-Mail ID"
                >
                    <Input
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="jd-creation-input"
                    />
                </Form.Item>
                <Form.Item
                    required={value ? false : true}
                    name="hiring_project_group_contact_number"
                    className="jd-creation-field-item"
                    label="Hiring Project Group Contact Number"
                >
                    <Input
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="jd-creation-input"
                    />
                </Form.Item>
                <Button
                    style={{ width: "100%", height: "40px", fontWeight: "600" }}
                    type="primary"
                    disabled={!isEditMode}
                    htmlType="submit"
                >
                    Submit Job Description
                </Button>
            </Form>
        </div>
    );
}
