import { create } from "zustand";

import axios from "../helper/axios";

const useMembersStore = create((set) => ({
    teamMembers: [],
    vendors: [],

    assignedTeamMembers: [],
    assignedVendorConnects: [],

    getTeamMembers: async () => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            const response = await axios.get(
                `/users/${userData.ID}/role/team_member`
            );

            set({ teamMembers: response.data });
        } catch (err) {
            console.error(err);
        }
    },
    getVendors: async () => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            const response = await axios.get(
                `/users/${userData.ID}/role/vendor_connect`
            );

            set({ vendors: response.data });
        } catch (err) {
            console.error(err);
        }
    },
    getAssignedMembers: async (jdId) => {
        try {
            const response = await axios.get(
                `/jds/${jdId}/assigned/team_member`
            );

            set({ assignedTeamMembers: response.data.team_member });
        } catch (err) {
            console.error(err);
        }

        try {
            const response = await axios.get(
                `/jds/${jdId}/assigned/vendor_connect`
            );

            set({ assignedVendorConnects: response.data.vendor_connect });
        } catch (err) {
            console.error(err);
        }
    },
}));

export default useMembersStore;
