import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/down.png";

import "./sidebar.scss";

function Sidebar() {
  const [isCollapsedJD, setIsCollapsedJD] = useState(true);
  const [isCollapsedJDrs, setIsCollapsedJDrs] = useState(true);

  const handleToggleCollapsejd = () => {
    setIsCollapsedJD(!isCollapsedJD);
  };
  const handleToggleCollapsers = () => {
    setIsCollapsedJDrs(!isCollapsedJDrs);
  };

  const setStatus = (data) => {
    console.log(data);
    localStorage.setItem("status", JSON.stringify({ status: data }));
  };
  return (
    <div className="sidebar-wrapper">
      {/* <Link className="sidebar-link" to="/">
                Dashboard
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedJD ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapsejd}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedJD ? "false" : "true"}
        >
          JDs
          <img
            src={arrowIcon}
            alt="Arrow"
            className={`arrow-icon ms-2 ${isCollapsedJD ? "" : "rotate"}`}
          />
        </button>

        {!isCollapsedJD && (
          <ul id="home-collapse" className="listShow">
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/jds", state: { status: "active" } }}
              >
                Active
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/jds", state: { status: "hold" } }}
                onClick={() => setStatus("hold")}
              >
                Hold
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/jds", state: { status: "closed" } }}
                onClick={() => setStatus("closed")}
              >
                Closed
              </Link>
            </li>
            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>

      {/* <Link className="sidebar-link" to="/jds">
                Job Descriptions
            </Link> */}
      <Link className="sideFont" to="/resumes " style={{ marginLeft: "12px" }}>
        Resumes
      </Link>
      {/* <Link className="sidebar-link" to="/resume_source">
                Resume Sourcing
            </Link> */}

      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedJDrs ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapsers}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedJDrs ? "false" : "true"}
        >
          Resume Sourcing
          <img
            src={arrowIcon}
            alt="Arrow"
            className={`arrow-icon ms-2 ${isCollapsedJDrs ? "" : "rotate"}`}
          />
        </button>

        {!isCollapsedJDrs && (
          <ul id="home-collapse" className="">
            <li className="list-group-item">
            <Link className="sidebar-link" to="/resume_source">
               All
              </Link>
              <Link className="sidebar-link" to="/From_My_Team">
                From My Team
              </Link>
            </li>
            <li className="list-group-item">
              <Link className="sidebar-link" to="/From_Vendors">
                From Vendors
              </Link>
            </li>

            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>
    </div>
  );
}

export default Sidebar;
