import React, { useEffect } from "react";
// import { actions } from "../../actions";
// import { useSelector, useDispatch } from "react-redux";

import "./dashboard.scss";
import ActiveSummary from "../ActiveSummaryBlock";

const activeJobs = [
    { id: "1", content: "1 new Job Added by XYZ" },
    { id: "2", content: "1 new Job Added by XYZ" },
    { id: "3", content: "1 new Job Added by XYZ" },
];

function Dashboard() {
    // const dispatch = useDispatch();
    // const vendorid = useSelector((state) => state.authReducer.vendorId);
    // useEffect(() => {
    //     console.log("dispatching get jd");
    //     dispatch(actions.Getjds(vendorid));
    // }, [vendorid]);

    return (
        <div className="dashboard-wrapper">
            <div className="activity-container">
                <ActiveSummary
                    data={activeJobs}
                    buttonName="See Job"
                    title="Activity Summary for Jobs (Last 24 hours)"
                />
            </div>
            <div className="activity-container">
                <ActiveSummary
                    data={activeJobs}
                    buttonName="See Resumes"
                    title="Activity Summary for Resumes (Last 24 hours)"
                />
            </div>
            <div className="activity-container">
                <ActiveSummary
                    data={activeJobs}
                    buttonName="See Assessments"
                    title="Activity Summary for Assessments (Last 24 hours)"
                />
            </div>
        </div>
    );
}

export default Dashboard;
