const initialstate = {
    jdData: {},
 
  };
  
  export const Jdinfo = (state = initialstate, action) => {
    switch (action.type) {
      case "JD_INFO":
        console.log("JD_LIST reducer");
        return {
          ...state,
  
          jdData: action.payload,
        };
  
      default:
        return state;
    }
  };
  