import { act } from "react-dom/test-utils"

const initialstate = {}

export const authReducer =(state = initialstate,action )=>{
    switch(action.type){
       
        case "SIGNUP":
            console.log("signup reducer")
        return {
            ...state,


        }
        case "SIGNIN": 
        console.log("SIGNIN",action.payload)
        return {
            ...state,
            authFlag : true,
            userID : action.payload.ID,
            accountType : action.payload.account_type,
            userName :  action.payload.username,
            organization : action.payload.organization,
            email : action.payload.email



   

        }
        case "AUTHORIZE":
            console.log("auth")
            return {
                ...state,
                token : action.payload.token,
                userID : action.payload.userId,
                AuthFlag : true
            }
         case "RESET_PASSWORD":
            return{
                ...state,
                messages : action.payload.message

            }
         case "ERROR_HANDLE":
            console.log("ERROR_HANDLE called");
                return{
                    ...state,
                     error_messages : action.payload.message
    
                }

    default : 
    return state
    }

}