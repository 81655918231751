const initialstate = {
    resumes: [],
  };
  
  export const Resumesreducer = (state = initialstate, action) => {
    switch (action.type) {
      case "RESUMES":
        console.log("RESUMES reducer");
        return {
          ...state,
          resumes: action.payload,
        };
  
      default:
        return state;
    }
  };
  