import React, { useEffect, useState } from "react";
import { Button ,Dropdown, Menu  } from "antd";
import { useParams } from "react-router-dom";
import {  useDispatch } from "react-redux";


import JDCreationForm from "../JDCreationForm";
import JDAssignmentForm from "../JDAssignmentForm";
import JDSummary from "../JDSummary";

import useJDStore from "../../store/jd.store";
import { actions } from "../../actions";

import "./createJd.scss";

function CreateJD() {
    const [currentView, setCurrentView] = useState("description");
    const { value } = useParams();

    const { getJdByID, selectedJD } = useJDStore();
    const dispatch = useDispatch();

    useEffect(() => {
        if (value) {
            getJdByID(value);
        }
    }, []);

    const view = {
        description: JDCreationForm,
        assignment: JDAssignmentForm,
        summary: JDSummary,
    };

    const handleMenuClick = (e) => {
        console.log('Clicked on menu item:', e);
        var userData = JSON.parse(localStorage.getItem("userDetails"));
        dispatch(actions.UpdateStatus(userData.account_type,userData.ID,value,e.key))
        // You can handle the menu item click event here
    };
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const menu = (
        <Menu onClick={handleMenuClick}>
            {/* Define menu items here */}
            <Menu.Item key="active" >active </Menu.Item>
            <Menu.Item key="hold" >hold</Menu.Item>
            <Menu.Item key="closed" >close</Menu.Item>
        </Menu>
    );

    const CurrentView = view[currentView];

    return (
        <div className="create-jd-wrapper">
            {value ? (
                <div className="jd-primary-info-wrapper" style={{ textAlign: "center" }}>
                    <div className="jd-primary-info">
                        <div className="job-id-tag"> job-ID-{selectedJD?.ID}
                        <p style={{marginLeft:"40px",marginTop : "15px", fontWeight:"600",fontSize : "16px", color :"#000000"}}>{selectedJD?.roles_and_responsibility}</p>
                        </div>
                      
                    </div>
                    <div className="jd-primary-info">
            <Dropdown
                overlay={menu}
                trigger={['click']}
                visible={dropdownVisible}
                onVisibleChange={(visible) => setDropdownVisible(visible)}
            >
                <Button className="jd-primary-btn">
                  <span style={{color : "#008fff"}}> status :  </span>  {selectedJD?.status} {/* Display selected status */}
                </Button>
            </Dropdown>
        </div>
                </div>
            ) : null}
            <div className="jd-creation-form-options-wrapper">
                <div className="jd-creation-form-first-options-wrapper">
                {value ? (  <Button
                        onClick={() => setCurrentView("description")}
                        type="primary"
                        className={`jd-form-option-btn ${
                            currentView === "description" &&
                            "jd-form-option-btn-active"
                        }`}
                    >
                        Description
                    </Button> ):  (  <Button
                        onClick={() => setCurrentView("description")}
                        type="primary"
                        className={`jd-form-option-btn ${
                            currentView === "description" &&
                            "jd-form-option-btn-active"
                        }`}
                    >
                        Creating New JD
                    </Button> )}
                    {value ? (
                        <>
                            <Button
                                onClick={() => setCurrentView("assignment")}
                                type="primary"
                                className={`jd-form-option-btn ${
                                    currentView === "assignment" &&
                                    "jd-form-option-btn-active"
                                }`}
                            >
                                Assignment
                            </Button>
                            <Button
                                onClick={() => setCurrentView("summary")}
                                type="primary"
                                className={`jd-form-option-btn ${
                                    currentView === "summary" &&
                                    "jd-form-option-btn-active"
                                }`}
                            >
                                Resume
                            </Button>
                            <Button
                                type="primary"
                                className="jd-form-option-btn"
                            >
                                Available
                            </Button>
                            <Button
                                type="primary"
                                className="jd-form-option-btn"
                            >
                                Shared resumes
                            </Button>
                            <Button
                                type="primary"
                                className="jd-form-option-btn"
                            >
                                Skill Matrix
                            </Button>
                        </>
                    ) : null}
                </div>
                {value ? (
                    <Button type="primary" className="jd-form-option-btn">
                        20-10-2023
                    </Button>
                ) : null}
            </div>
            <CurrentView setCurrentView={setCurrentView} />
        </div>
    );
}

export default CreateJD;
