import React from "react";
import { Modal, Form, Input, Button, Radio } from "antd";

import useSubUserStore from "../../store/subUserModal.store";

import "./subUserAddModal.scss";

export default function SubUserAddModal() {
    const { isModalOpen, handleModalCancel, handleUserAdd, currentMemberType } = useSubUserStore();

    console.log("currentMemberType", currentMemberType)

    const handleFormSubmit = (value) => {
        handleUserAdd(value);
        handleModalCancel();
    };

    return (
        <Modal
            title="Add User"
            visible={isModalOpen}
            footer={null}
            onCancel={handleModalCancel}
        >
            {currentMemberType === "team" ? (
                <Form
                    labelCol={{ flex: "150px" }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                    onFinish={handleFormSubmit}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add User's Name",
                            },
                        ]}
                        label="Name"
                        name="username"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Organization",
                            },
                        ]}
                        label="Organization"
                        name="organization"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                maxLength: 10,
                                message: "Please Add Contact Details",
                            },
                        ]}
                        label="Mobile No."
                        name="contact"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Email",
                            },
                        ]}
                        label="Email ID"
                        name="email"
                    >
                        <Input type="email" />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Select Admin Rights",
                            },
                        ]}
                        label="Admin"
                        name="admin"
                    >
                        <Radio.Group>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Button htmlType="submit" type="primary">
                        Add User
                    </Button>
                </Form>
            ) : (
                <Form
                    labelCol={{ flex: "150px" }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                    onFinish={handleFormSubmit}
                >
                    
                   
                   
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Email",
                            },
                        ]}
                        label="Email ID"
                        name="email"
                    >
                        <Input type="email" />
                    </Form.Item>


                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Add Email",
                            },
                        ]}
                        label="Password"
                        name="password"
                    >
                        <Input type="password" />
                    </Form.Item>
                  
                    <Button htmlType="submit" type="primary">
                        Add User
                    </Button>
                </Form>
            )}
        </Modal>
    );
}
