const initialstate = {
    vendors: [],
  };
  
  export const VendorConnect = (state = initialstate, action) => {
    switch (action.type) {
      case "VENDOR_CONNECT":
        console.log("VENDOR_CONNECT reducer");
        return {
          ...state,
          vendors: action.payload,
        };
  
      default:
        return state;
    }
  };