import React, { useEffect } from 'react';
import Table from "../Table";
import { Button, Empty } from "antd";
import resumeStats from "../../store/resumeSource.store";

function From_team_member() {
    const currentPath = window.location.pathname;
    const lastPartAfterSlash = currentPath.substring(currentPath.lastIndexOf('/') + 1);
    console.log("Last Part After Slash:", lastPartAfterSlash);
    localStorage.setItem("resumeSourceFor",lastPartAfterSlash)
    

     const {getResumeStats,resumeStatsData} = resumeStats()

     useEffect(() => {
        console.log("team member stats called")
        getResumeStats()
     }, [lastPartAfterSlash]);
     useEffect(() => {
        console.log(resumeStatsData)
       
     }, [resumeStatsData]);


    const titleList = [
        "ID",
        "Member ID",
        "Member Name",
        "No. of assigned jd",
        "Resume Upload",
        "Qualityh resume",
        "Screen rejected",
        "L1",
        "Final Selected",
        "Joined",
       
    ];
      const jdData = [1,2,3,4]

    const jdsDetailData = resumeStatsData?.map((jds, idx) => {
        return {
            id:  idx+1,
            team_member_id: jds?.team_member_id,
            team_member_name: jds?.team_member_name,
            no_of_jds: jds?.num_of_assigned_jd,
            resumes_uploaded : jds?.resumes_uploaded

           
        };
    });

    return ( <div>
    
         {jdData?.length > 0 ? (
                <Table
                    isClickable={true}
                    isJDPage={false}
                    titleList={titleList}
                    data={jdsDetailData}
                    
                />
            ) : (
                <Empty style={{ margin: "60px" }} />
            )}
    </div> );
}

export default From_team_member;