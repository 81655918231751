import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { Jdsreducer  } from "./jdsDataReducer";
import  {authReducer} from "./authReducer";
import { Companyreducer } from "./companyReducer";
import { Jdinfo  } from "./specificJdReducer";
import { Resumesreducer  } from "./resumes";
import { VendorConnect } from "./vendorconnect";
import {Employes } from "./employes";
import { Resumesstats  } from "./resumeStats";



export default combineReducers({
    form : formReducer,
    jdForm : false,
    Jdsreducer,
    authReducer,
    Companyreducer,
    Jdinfo,
    Resumesreducer,
    VendorConnect,
    Employes,
    Resumesstats

})