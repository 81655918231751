import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Button, Select, message } from "antd";
import useMembersStore from "../../store/members.store";
import useJDStore from "../../store/jd.store";

import "./jdAssignmentForm.scss";

export default function JDAssignmentForm({ setCurrentView }) {
  const params = useParams();

  const { getVendors, vendors, getTeamMembers, teamMembers, assignedTeamMembers, assignedVendorConnects, getAssignedMembers } = useMembersStore();
  const { postAssignment, selectedJD } = useJDStore();
  const [assignedTeamMembersStr, setassignedTeamMembersStr] = useState("not assigend yet")
  const [assignedVendorConnectsStr, setassignedVendorConnectsStr] = useState("not assigend yet")
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {

        await getVendors();
        await getTeamMembers();

        await getAssignedMembers(params.value);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [getVendors, getTeamMembers, getAssignedMembers]);

  useEffect(() => {
    if (assignedTeamMembers && assignedTeamMembers.length > 0) {
      setassignedTeamMembersStr("already assigned to " + assignedTeamMembers.map((member) => member.username).join(" ,"));
    }


    if (assignedVendorConnects && assignedVendorConnects.length > 0) {
      setassignedVendorConnectsStr("already assigned to " + assignedVendorConnects.map((member) => (member.email)).join(" ,"));
    }
  }, [assignedTeamMembers, assignedVendorConnects])

  useEffect(() => {
    console.log(selectedJD);
    setFormKey((prevKey) => prevKey + 1);
  }, [selectedJD]);

  const AssignmentValues = {
    spoc_TA: selectedJD?.spoc_TA,
    TA_manager: selectedJD?.TA_manager,
    client_name: selectedJD?.client_name,
    client_email: selectedJD?.client_email,
    client_contact: selectedJD?.client_contact,
  };


  const handleSubmit = async (values) => {
    try {
      const res = await postAssignment(params.value, values);

      if (res) {
        message.success("JD assignment is successful");
        setCurrentView("summary");
      } else {
        message.error("There was an issue with JD assignment. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting JD assignment:", error);
      message.error("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="jd-assignment-form-wrapper">
      <h1 className="jd-assignment-form-title">JD Assignment</h1>
      <Form
        key={formKey}
        className="jd-assignment-form"
        name="jd-assignment-form"
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        initialValues={AssignmentValues}
      >
        <div className="triple-input-wrapper">

          <Form.Item
            className="jd-assignment-field-item"
            label="Assign to TA"
            name="team_members"
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select your Team members"
              className="jd-assignment-select"
            >
              {teamMembers && teamMembers.map((member, idx) => {
                return (
                  <Select.Option key={idx} value={member.ID}>
                    {member.username}
                  </Select.Option>
                );
              })}
            </Select>

          </Form.Item>

          <Form.Item
            className="jd-assignment-field-item"
            label="Spoc TA"
            name="spoc_TA"
          >
            <Input className="jd-assignment-input" />
          </Form.Item>
          <Form.Item
            className="jd-assignment-field-item"
            label="TA Manager"
            name="TA_manager"
          >
            <Input className="jd-assignment-input" />
          </Form.Item>
        </div>
        <div className="triple-input-wrapper">

          <Form.Item
            className="jd-assignment-field-item"
          >
            <span style={{ color: 'green' }}>{assignedTeamMembersStr}</span>
          </Form.Item>


        </div>


        <Form.Item
          className="jd-assignment-field-item"
          label="Vendor List(Multiple Vendor Selection Possible)"
          name="vendors"
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select your vendors"
            className="jd-assignment-select"
          >
            {vendors &&
              vendors.map((vendor, idx) => (
                <Select.Option key={idx} value={vendor.ID}>
                  {vendor.email}
                </Select.Option>
              ))}
          </Select>

        </Form.Item>
        <Form.Item
          className="jd-assignment-field-item"
        >
          <span style={{ color: 'green' }}>{assignedVendorConnectsStr}</span>
        </Form.Item>
        <h1 className="jd-assignment-form-title">Client Contact Details</h1>
        <div className="triple-input-wrapper">
          <Form.Item
            className="jd-assignment-field-item"
            label="Contact Name"
            name="client_name"
            rules={[{ required: true, message: 'client name is required' }]}
          >
            <Input className="jd-assignment-input" />
          </Form.Item>
          <Form.Item
            className="jd-assignment-field-item"
            label="Contact Number"
            name="client_contact"
            rules={[{ required: true, message: 'client contact is required' }]}
          >
            <Input className="jd-assignment-input" />
          </Form.Item>
          <Form.Item
            className="jd-assignment-field-item"
            label="Email-ID"
            name="client_email"
            rules={[{ required: true, message: 'client email is required' }]}
          >
            <Input className="jd-assignment-input" />
          </Form.Item>
        </div>

        <Button
          style={{ width: "100%", height: "40px", fontWeight: "600" }}
          type="primary"
          htmlType="submit"
        >
          Submit Job Description Assignment
        </Button>
      </Form>
    </div>
  );
}
