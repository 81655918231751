import React, { useEffect } from "react";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../store/jd.store";
import { useLocation } from "react-router-dom";

import Table from "../Table";

import "./jds.scss";

export default function JDs(props) {
  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { getAllJds, jdData } = useJDStore();

  useEffect(() => {
    getAllJds();
  }, [status]);

  const titleList = [
    "Sr. No.",
    "Client",
    "Job ID",
    "Job Title",
    "Available Resumes",
    "Screen Rejected",
    "Interviews",
    "Final Select",
    "Date Added",
    "Prority",
    "Job Status",
    "Matching Resume",
    "Assigned to",
    "SPOC",
    "Hiring Project Team",
    "Date Modified",
  ];

  const jdsDetailData = jdData?.map((jds, idx) => {
    return {
      id: idx + 1,
      client_name: jds?.client_name,
      jobId: jds?.ID,
      title: jds?.title,
      available_resumes: jds?.available_resumes,
      screen_rejected: jds?.screen_rejected,
      interviews: jds?.interviews,
      final_select: jds?.final_select,
      date_added: jds?.date_added,
      priority: jds?.priority,
      status: jds?.status,
      matching_resume: jds?.matching_resume,
      assigned_to: jds?.assigned_to,
      SPOC: jds?.SPOC,
      hiring_project_team_name: jds?.hiring_project_team_name,
      date_modified: jds?.date_modified,
    };
  });

  return (
    <div className="jds-wrapper">
      <div className="jds-header-wrapper">
        <h1 className="jds-title"></h1>
        <Button
          onClick={() => navigate("/createJd")}
          className="jd-create-button"
          type="primary"
        >
          Create Job description
        </Button>
      </div>
      {jdData?.length > 0 ? (
        <Table
          isClickable={true}
          isJDPage={true}
          titleList={titleList}
          data={jdsDetailData}
        />
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
    </div>
  );
}
