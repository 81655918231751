const initialstate = {
  company: [],
};

export const Companyreducer = (state = initialstate, action) => {
  switch (action.type) {
    case "GET_COMPANY":
      console.log("get company reducer");
      return {
        ...state,
        company: action.payload, // Update the 'company' array with the payload response
      };

    case "UPDATE_COMPANY":
      console.log("Update company called company reducer");
      return {
        ...state,
        company: [...state.company,action.payload] // Update the 'company' array with the payload response
      };

    default:
      return state;
  }
};
