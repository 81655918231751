import axios from "../helper/axios";
import { create } from "zustand";

const useResumeSourcingStore = create((set) => ({
  // vendor_connect
  resumeStatsData: [],
  selectedMembers: [], // Fixed typo here

  getResumeStats: async () => {
    try {
      const userDataString = localStorage.getItem("userDetails");
      if (!userDataString) {
        console.log("User details not found in localStorage");
        return;
      }

      const userData = JSON.parse(userDataString);
      var dataTypeToSend = ""
      if (!userData || !userData.ID) {
        console.log("Invalid user details");
        return;
      }
       const dataType = localStorage.getItem("resumeSourceFor")
       console.log( typeof dataType)
       if(dataType == "From_My_Team"){
         dataTypeToSend = "team_member"
       }else if (dataType == "From_Vendors"){
        dataTypeToSend = "vendor_connect"
       }else{
        console.log("please provide stats type")
        return
       }
      const response = await axios.get(
        `/users/${userData.ID}/resumestats/${dataTypeToSend}`
      );
      set({ resumeStatsData: response.data });
    } catch (error) {
      console.error(error);
    }
  },
  getMember_Resume_Stats: async () => {
    try {
      const userDataString = localStorage.getItem("userDetails");
      if (!userDataString) {
        console.log("User details not found in localStorage");
        return;
      }

      const userData = JSON.parse(userDataString);
      var dataTypeToSend = ""
      if (!userData || !userData.ID) {
        console.log("Invalid user details");
        return;
      }
       const dataType = localStorage.getItem("resumeSourceFor")
       const teammemberID = localStorage.getItem("teammemberID")

       

       
       console.log( typeof dataType)
      //  if(dataType == "From_My_Team"){
      //    dataTypeToSend = "team_member"
      //  }else if (dataType == "From_Vendors"){
      //   dataTypeToSend = "vendor_connect"
      //  }else{
      //   console.log("please provide stats type")
      //   return
      //  }
      const response = await axios.get(
        `/users/${userData.ID}/resumestats/team_member/${teammemberID}`
      );
      set({ selectedMembers: response.data });
    } catch (error) {
      console.error(error);
    }
  },


}));

export default useResumeSourcingStore;
