import axios from "../helper/axios";
import { create } from "zustand";

const useJDStore = create((set) => ({
    jdData: [],
    selectedJD: {},
    jdCreationError: null,
    isJDCreated: false,
    getAllJds: async () => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            const statusvalue = JSON.parse(localStorage.getItem("status"));
            const response = await axios.get(
                `/jds?account_type=${userData.account_type}&owner_id=${userData.ID}&status=${statusvalue.status} `
            );
            set({ jdData: response.data });
        } catch (error) {
            console.log(error);
        }
    },
    getJdByID: async (id) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            const response = await axios.get(
                `/${userData.account_type}/${userData.ID}/jds/${id}`
            );
            set({ selectedJD: response.data });
        } catch (error) {
            console.log(error);
        }
    },
    createJd: async (body) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));

            const response = await axios.post(
                `/${userData.account_type}/${userData.ID}/jds`,
                body
            );

            console.log(response);

            if (response.status === 200) {
                set({ isJDCreated: true });
            }
        } catch (error) {
            set({ jdCreationError: error });
        }
    },
    updateJd: async (body, jdId) => {
        try {
            console.log("called", body)
            const userData = JSON.parse(localStorage.getItem("userDetails"));

            await axios.patch(
                `/${userData.account_type}/${userData.ID}/jds/${jdId}`,
                body
            );
        } catch (error) {
            set({ jdCreationError: error });
        }
    },
    postAssignment: async (id, value) => {
        console.log(value);
        var vendors = []
        if (value.vendors) {
            vendors = [...value.vendors]
        }

        var teamMembers = []
        if (value.team_members) {
            teamMembers = [...value.team_members]
        }
        try {
            await axios.put(`jds/${id}/assign/team_member`, {
                vendors: vendors,
                team_members: teamMembers,
                spoc_TA: value.spoc_TA,
                TA_manager: value.TA_manager,
                client_name: value.client_name,
                client_contact: value.client_contact,
                client_email: value.client_email,
            });

            return true;
        } catch (error) {
            console.log(error);
        }
    },

    clearJD: () => {
        selectedJD: { }
    }
}));

export default useJDStore;
