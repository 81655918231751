import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import home from "../../assets/home.png";
import "./breadcrumbs.css";

function Breadcrumbs() {
  const location = useLocation();
  let currentLink = "";
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index, array) => {
      currentLink += `/${crumb}`;

      return crumb !== "dashboard" ? (
        <div className="crumb" key={crumb}>
          <span>&gt;</span>
          <NavLink
            className="breadCrumbData"
            to={currentLink}
            activeClassName="activeLink" // Add a class for the active link
          >
            <span>{crumb}</span>
          </NavLink>
        </div>
      ) : null;
    });

  return (
    <div className="breadcrumbs">
      <NavLink to={"/"} activeClassName="activeLink">
        <img  className="homeimg" src={home} alt="Home" />
      </NavLink>
      {crumbs}
    </div>
  );
}

export default Breadcrumbs;
