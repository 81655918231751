import React,{useEffect} from 'react';
import Table from "../Table";
import { useParams } from "react-router-dom";
import { Empty } from 'antd';
import resumeStats from "../../store/resumeSource.store";



function Particularmember() {
  const { value } = useParams();
  const {getResumeStats,selectedMembers,getMember_Resume_Stats} = resumeStats()
  
  useEffect(() => {
    localStorage.setItem("teammemberID",value)
    getMember_Resume_Stats()
  }, [value]);

    const member =  [
        {
            
            "id":0,"jd_id":"6576c19646f2724dca8fb34a","jd_title":"Software engeenere","resumes_uploaded":1
        },
        {
           
            "id":0,"jd_id":"6576c19646f2724dca8fb34a","jd_title":"Software engeenere","resumes_uploaded":1
           
        },
        {
            
            "id":0,"jd_id":"6576c19646f2724dca8fb34a","jd_title":"Software engeenere","resumes_uploaded":1
           
        }
    ]
   

    const titleList = [
        "ID",
        "Resume Uploaded",
        "Quality Resume",
        "Screen rejected",
        "L1",
        "Final Selected",
        "Joined",
        "Assign/unassign",
      ];


    const Card = ({ dataArrray })=>{
        return (
            <div style={{marginTop : "30px" ,marginBottom : "100px"}}>
            <h1>{dataArrray.jd_id}</h1>
            <h1>{dataArrray.jd_title}</h1>

            {dataArrray?.length > 0 ? (
        <Table
          isClickable={true}
          isJDPage={true}
          titleList={titleList}
          data={dataArrray}
        />
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
            
            {/* <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Resumes Uploaded</th>
                  <th>Quality Resume</th>
                  <th>Screen Rejected</th>
                  <th>L1</th>
                  <th>Final Selected</th>
                  <th>Joined</th>
                </tr>
              </thead>
              <tbody>
                {dataArrray.map((data, index) => (
                  <tr key={index}>
                    <td>{data.id}</td>
                    <td>{data.resumesUploaded}</td>
                    <td>{data.qualityresume}</td>
                    <td>{data.screenrejected}</td>
                    <td>{data.l1}</td>
                    <td>{data.finalselected}</td>
                    <td>{data.joined}</td>
                  </tr>
                ))}
              </tbody>
            </table> */}
          </div>
        )
    }
    return ( 
        <div>
            
            
               
            <Card  dataArrray={selectedMembers}  />
     
        </div>
    
    
    );
}

export default Particularmember;