import "./App.css";
import Header from "./components/header";
import { Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";
// Import your other components here
import { useSelector } from "react-redux";
import Mainpage from "./components/mainpage";
import Dashboard from "./components/dashboard";
import Alljds from "../src/components/alljds/alljds";
import Jd from "../src/components/particularjds";
import Resumes from "../src/components/particularjds/resumes/resumes";
import Jd_description from "../src/components/particularjds/jd_descriptions";
import Createjd from "./components/createJD";
import CandidateDetails from "../src/components/particularjds/resumes/resumecomponents/candidateDetails/candidateDetaild/candidateDetails";
import ResumeSummary from "../src/components/particularjds/resumes/resumecomponents/summary/summary";
import Summary from "../src/components/particularjds/resumes/resumecomponents/summary/summary";
import Signin from "./authentication/Signin";
import Signup from "./authentication/Signup";
import Otpscreen from "./components/otpscreen/otpscreen";
import Settings from "./components/settings";
import Assignemnts from "./components/particularjds/assignment/assignments";
import Allresumes from "./components/allResumes";
import ResumeSource from "./components/resumeSource";
import Profile from "./components/profile/profile";
import JDs from "./components/JDs";
import CandidateList from "./components/CandidateList";
import Fromteammember from "./components/fromTeamMember/fromTeamMember";
import Particularmembers from "./components/particularMemberDetails";
import MainpageStats from "./components/fromTeamMember/mainpage";



var PrivateRoute = (props) => {
  const authFlag = useSelector((state) => state.authReducer.authFlag);
  var authDetail = localStorage.getItem("userDetails");

  const auth = localStorage.getItem("token");
  return authDetail ? <Outlet /> : <Navigate to="/login" />;
  // return <Outlet />;
};
function App() {
  return (
    <div id="main">
      <div id="body">
        <Routes>
          <Route path="/login" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/otp" element={<Otpscreen />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Mainpage />}>
              <Route index element={<Navigate to="dashboard" />} />
              <Route path="profile" element={<Profile />} />

              <Route exact path="dashboard" element={<Dashboard />} />
              <Route path="resume_source" element={<ResumeSource />} />
              <Route path="From_My_Team" element={<MainpageStats />}>
                {/* Add more routes inside Fromteammember */}
                {/* <Route index element={<Navigate to="" />} /> */}
                <Route path="" element={<Fromteammember />} />

                <Route path=":value" element={<Particularmembers />} />
                {/* Add as many routes as needed */}
              </Route>







              <Route path="From_Vendors" element={<Fromteammember />} />
              <Route path="particularMember" element={<Particularmembers />} />

              <Route path="settings" element={<Settings />} />
              <Route path="jds">
                <Route index element={<JDs />} />
                <Route path=":value" element={<Createjd />} />
              </Route>
              <Route path="createJd" element={<Createjd />} />
              <Route path="resumes" element={<CandidateList />} />

              <Route index element={<Outlet />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
