const initialstate = {
    resume_stats: [],
  };
  
  export const Resumesstats= (state = initialstate, action) => {
    switch (action.type) {
      case "RESUMES_STATS":
        return {
          ...state,
          resume_stats: action.payload,
        };
  
      default:
        return state;
    }
  };
  