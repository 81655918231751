/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Avatar, Dropdown } from "antd";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../assets/QuallyHireGreen.png";
import Notifications from "../../assets/notifications.svg";
import Settings from "../../assets/settings.svg";

import "./header.scss";

const Header = () => {
    const navigate = useNavigate();

    var navigateTo = () => {
        navigate("/settings");
    };

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const handleLogOut = () => {
        localStorage.removeItem("userDetails");
        window.location.reload();
    };

    const items = [
        {
            label: (
                <div className="log-out" onClick={handleLogOut}>
                    Log Out
                </div>
            ),
            dander: true,
            key: "0",
        },
    ];

    return (
        <nav className="navbar-wrapper">
            <div className="logo-wrapper">
                <img src={Logo} className="logo" alt="QuallyHire" />
            </div>
            <div className="services-links-wrapper">
            <Link className="links" to="/">
                Home
            </Link>
                <a className="links" href="#">
                Customers
                </a>
               
                <a className="links" href="#">
                HR Contact Book
                </a>
                <a className="links" href="#">
                Communications
                </a>
              
            </div>
            <div className="info-wrapper">
                <img
                    onClick={navigateTo}
                    className="info-logo"
                    src={Settings}
                    alt="Settings"
                />
                <img
                    className="info-logo"
                    src={Notifications}
                    alt="Notifications"
                />
                <Dropdown
                    menu={{
                        items,
                    }}
                    trigger={["click"]}
                >
                    <Avatar className="user-avatar">
                        {userDetails.username[0]}
                    </Avatar>
                </Dropdown>
            </div>
        </nav>
    );
};

export default Header;
