import axios from "../helper/axios";


export function Createcompany(data,vendorid) {
    console.log("Createjd data",data)
    console.log("Createjd vendor id",vendorid)

    return async (Dispatch) => {
      axios
        .post(`/vendors/${vendorid}/companies`, data)
        .then((res) => {
          console.log(res);
          Dispatch({ type: "UPDATE_COMPANY", payload: res.data })
         
            
        })
        .catch((e) => console.log(e));
    };
  }


  export function Getcompany(vendorid) {
    console.log("Getcompany called")
    
    return async (Dispatch) => {
      axios
        .get(`/vendors/${vendorid}/companies`)
        .then((res) => {
          console.log(res);
          Dispatch({ type: "GET_COMPANY", payload: res.data });
            
        })
        .catch((e) => console.log(e));
    };
  }