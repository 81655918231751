import React from "react";
import { Button } from "antd";

import "./activeSummary.scss";

export default function ActiveSummary({ data, buttonName, title }) {
    return (
        <div className="activity-summary-wrapper">
            <h1 className="activity-summary-title">{title}</h1>
            <div className="activity-wrapper">
                {data.map((info) => (
                    <div className="activity">
                        <div className="activity-name">{info.content}</div>
                        <Button type="primary" className="activity-button">
                            {buttonName}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );
}
