import axios from "axios";

const instance = axios.create({
    baseURL: "https://api.qhrflow.com",
    // baseURL: "http://localhost:4200",
    // timeout: 5000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    withCredentials: true,
});

export default instance;
