import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./resumeanalysys.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import Dropdown from "react-bootstrap/Dropdown";
import useResumeStore from "../../../store/resume.store";
function ResumeAnalysis() {
  var resumeAnalysysDispalyData = {
    score_list: {
      keyword_scores: {
        keywords: {
          keyword1: 0,
          keyword2: 0,
        },
        score: 0,
      },
      skill_scores: {
        keywords: {
          skill1: 0,
          skill1: 0,
        },
        score: 0,
      },
    },
    score: 0,
  };
  const now = 60;

  const { value } = useParams();

  const { resumes, getResumeSkill, resumeAnalysysData } = useResumeStore();
  const dropdownValue = resumeAnalysysData.name || "select candidate"
  const name = resumeAnalysysData.name || "";
  const email = resumeAnalysysData.email || "";
  const contact = resumeAnalysysData.contact || "";
  const MandatoryKeyword = resumeAnalysysData.mandatory_keywords || "";
  const optionalKeyword = resumeAnalysysData.optional_keywords || "";
  if(resumeAnalysysData && Object.keys(resumeAnalysysData).length > 0){
    resumeAnalysysDispalyData = resumeAnalysysData;

  }

  const keywordScores =
    resumeAnalysysDispalyData.score_list.keyword_scores.keywords || [];
  const skillScores =
    resumeAnalysysDispalyData.score_list.skill_scores.keywords || [];
  useEffect(() => {
    console.log(resumeAnalysysData);
  }, [value]);

  console.log(resumes);
  const newArray = resumes.map((item) => {
    return {
      id: item.ID,
      concatenatedValue: `${item.name} ${item.email}`,
    };
  });

  const breakdownanalysis = ["Final Anaysis","Skill Analysis","Gap Analysis","Exp Analysis","Education Analysis","Grammer Analysis","Frequent Hopper Analysis","Barred Company Analysis","Barred College Analysis"]

  console.log(newArray);

  const handleDropdownChange = (eventKey, event) => {
    console.log("function called");
    // Retrieve the selected item from newArray using the index
    const selectedItem = newArray[eventKey];
    // setSelectedValue(selectedItem);
    console.log(selectedItem);
    getResumeSkill(value, selectedItem.id);

    // Call your function here and pass the selected value
    // yourFunction(selectedItem);
  };

  return (
    <div>
      <div className="drpdown">
        <Dropdown onSelect={handleDropdownChange}  >
          <Dropdown.Toggle variant="outline-light" id="dropdown-basic" style={{backgroundColor :"#f8f9fa",color:"black"}}>
            {dropdownValue}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {newArray.map((item, index) => (
              <Dropdown.Item key={index} eventKey={index}>
                {item.concatenatedValue}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="firstgroup">
        <div className="form-group">
          <label htmlFor="exampleFormControlInput1">Name</label>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder=""
            value={name}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleFormControlInput1">Email ID</label>
          <input
            type="email"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder=""
            value={email}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleFormControlInput1">Contact Number</label>
          <input
            type="email"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder=""
            value={contact}
            readOnly
          />
        </div>
      </div>
      <div className="secondgroup">
        <div className="form-group" style={{ marginRight: "30px" }}>
          <label htmlFor="exampleFormControlInput1">Mandatory keyboard</label>
          <input
            type="email"
            className="form-control space"
            id="exampleFormControlInput1"
            placeholder=""
            value={MandatoryKeyword}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleFormControlInput1">Optional Keyword</label>
          <input
            type="email"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder=""
            value={optionalKeyword}
            readOnly
          />
        </div>
      </div>
      <div className="thirddiv">
        <div className="form-group" style={{ display: "flex" }}>
          <p>Final Analysis of Resume</p>
          <input
            style={{ width: "40%", gap: "40px" }}
            type="email"
            className="form-control semibold-20"
            id="exampleFormControlInput1"
            placeholder=""
            value="Final Analysis of Resume"
            readOnly

            
          />
        </div>
        <div className="form-group" style={{ display: "flex" }}>
          <p>Breakdown of Resume Analysis</p>
          <Dropdown onSelect={handleDropdownChange}  >
          <Dropdown.Toggle variant="outline-light" id="dropdown-basic" style={{backgroundColor :"#f8f9fa",color:"black"}}>
          Select Analysis Type
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {breakdownanalysis.map((item, index) => (
              <Dropdown.Item key={index} eventKey={index}>
                {item}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        </div>
      </div>

      <div className="bottomdiv">
        <div>
          <div className="botmdiv1">
            {Object.entries(keywordScores).map(([keyword, score], index) => (
              <div key={index} className="progress1">
                <p>{keyword}</p>
                <ProgressBar
                  style={{ height: "30px", width: "300px", marginLeft: "50px" }}
                  now={score}
                  label={`${score}%`}
                />
              </div>
            ))}
          </div>

          <div className="botmdiv1" style={{ marginTop: "20px" }}>
            {Object.entries(skillScores).map(([keyword, score], index) => (
              <div key={index} className="progress1">
                <p>{keyword}</p>
                <ProgressBar
                  style={{ height: "30px", width: "300px", marginLeft: "50px" }}
                  now={score}
                  label={`${score}%`}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="average">
          <p>Average Matching Score</p>
          <ProgressBar now={resumeAnalysysDispalyData.score} label={`${resumeAnalysysDispalyData.score}%`} />
        </div>
      </div>
    </div>
  );
}

export default ResumeAnalysis;
