const initialstate = {
    teamMembers: [],
  };
  
  export const Employes = (state = initialstate, action) => {
    switch (action.type) {
      case "EMPLOYEE":
        console.log("EMPLOYEE reducer");
        return {
          ...state,
          teamMembers: action.payload,
        };
  
      default:
        return state;
    }
  };