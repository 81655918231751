import React, { useState, useEffect } from "react";
import "./otpscreen.css";
import { useLocation } from "react-router-dom";
import { actions } from "../../actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/QuallyHireGreen.png";

function OTPForm() {
    const [otpInputs, setOtpInputs] = useState(["", "", "", "", "", ""]);
    const [timer, setTimer] = useState(10); // Initial timer value
    const [otpResent, setOtpResent] = useState(true); // Track if OTP has been resent
    const location = useLocation();
    const { inputData, type, dataToSend } = location.state || {};
    const contactEmail = inputData.email || "";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error,setError] = useState("")

    useEffect(() => {
        let interval;
        if (otpResent && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }else{
            setOtpResent(false);

        }

        return () => clearInterval(interval);
    }, [otpResent, timer]);

    const resendOTP = () => {
        // Implement resend OTP functionality here
        // You may call an API or perform any other necessary action
            let emailtosend = { email: contactEmail }

        dispatch(actions.SignIn(emailtosend, (error, status) => {
            if (!error) {
                
             
            } else {
              // Handle the error case
          
              console.error("API Error:", error);
              let e = error.split("or")
              setError(e[0] + "signup first")
             // setErrorMessage(error); // Set the error message in state
            }
          }));
          setOtpResent(true);
          setTimer(10); // Reset the timer

       
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const otpValue = otpInputs.join("");
        const dataToSend = {
            email: contactEmail,
            otp: otpValue,
        };

        if (type === "login") {
            dispatch(actions.Verify_signin_otp(dataToSend, () =>
                navigate("/dashboard")
            ));
        } else {
            dispatch(actions.Verify_acoount_otp(dataToSend, () =>
                navigate("/dashboard")
            ));
        }
    };

    const digitValidate = (index, value) => {
        value = value.replace(/[^0-9]/g, ""); // Only allow numeric digits
        const newOtpInputs = [...otpInputs];
        newOtpInputs[index] = value;
        setOtpInputs(newOtpInputs);
        if (value !== "" && index < 5) {
            // If a digit is entered and the index is not the last one
            document.getElementById(`otpInput${index + 1}`).focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === "Backspace" && index > 0 && otpInputs[index] === "") {
            // Backspace key is pressed and the current input is empty, so focus on the previous input field
            document.getElementById(`otpInput${index - 1}`).focus();
        }
    };

    return (
        <section className="vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                        <div className="" style={{ borderRadius: "15px" }}>
                            <div className="card-body p-4 text-center card-container">
                                <h5 className=" mb-5">
                                    <div className="header">
                                        <div>
                                            <img src={logo} alt="logo" />
                                        </div>
                                        <div>
                                            <h2 className="form-header">
                                                Signing up in QuallyHire
                                            </h2>
                                        </div>
                                    </div>
                                </h5>
                                <p>{contactEmail}</p>
                                <div className="title">
                                    Enter OTP received in your email ID
                                </div>
                                <form onSubmit={handleSubmit} className="mt-3">
                                    {otpInputs.map((input, index) => (
                                        <input
                                            key={index}
                                            id={`otpInput${index}`}
                                            className="otp"
                                            type="text"
                                            value={input}
                                            onChange={(e) =>
                                                digitValidate(
                                                    index,
                                                    e.target.value
                                                )
                                            }
                                            onKeyDown={(e) =>
                                                handleKeyDown(index, e)
                                            }
                                            maxLength={1}
                                        />
                                    ))}
                                    <hr className="mt-4" />
                                    {type === "login" ? (
                                        <button
                                            className="btn btn-primary btn-block mt-4 mb-4 customBtn"
                                            onClick={handleSubmit}
                                        >
                                            Sign In
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-primary btn-block mt-4 mb-4 customBtn"
                                            onClick={handleSubmit}
                                        >
                                            Verify OTP
                                        </button>
                                    )}
                                    <div className="d-flex center12">
                                        <p>
                                            {otpResent
                                                ? `Resend OTP in ${timer} seconds`
                                                : "Resend OTP"}
                                        </p>
                                        <button
                                            className="resendButton"
                                            disabled={otpResent}
                                            onClick={resendOTP}
                                        >
                                            CLICK HERE
                                        </button>
                                    </div>
                                    <p className="text-danger center">{error} </p>
                                </form>
                            </div>
                            <div className="footer">
                                <div>
                                    <p>
                                        QuallyHire is an QHRFlow Product from
                                        HMW Communications Pvt. Ltd.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OTPForm;
