import React from "react";
import { Button, Empty } from "antd";

import Table from "../Table";

import "./candidateList.scss";

const getCandidateList =
    JSON.parse(localStorage.getItem("candidateList")) || [];

const candidateDetailsListTable = [
    "Number",
    "Name",
    "Email Id",
    "Mobile",
    "Preferred Location",
    "Current Designation",
    "Notice Period (official days)",
    "Share to Client",
    "Notice Period (Negotiable days)",
    "LWD in Current Company",
    "Offer Joining Date",
    "Offer CTC",
    "Max Degree",
    "Current Company",
    "Current Location",
    "Referral",
    "Remarks",
    "Availability",
    "ECTC",
    "CCTC",
    "Date Added",
    "Uploaded By",
    "Vendor Name",
    "Source Medium",
];

const candidateDetailsData = getCandidateList?.map((candidate, idx) => {
    return {
        id: idx + 1,
        name: candidate?.name,
        email: candidate?.email,
        mobile: candidate?.mobile,
        preferredLocation: candidate?.preferredLocation,
        currentDesignation: candidate?.currentDesignation,
        noticePeriodOfficial: candidate?.noticePeriodOfficial,
        share_to_client: "share to client",
        noticePeriodNegotiable: candidate?.noticePeriodNegotiable,
        lwd: candidate?.lwd,
        offerJoiningDate: candidate?.offerJoiningDate,
        offerCTC: candidate?.offerCTC,
        maxDegree: candidate?.maxDegree,
        currentCompany: candidate?.currentCompany,
        currentLocation: candidate?.currentLocation,
        referral: candidate?.referral,
        remarks: candidate?.remarks,
        availability: candidate?.availability,
        ECTC: candidate?.ECTC,
        CCTC: candidate?.CCTC,
        dateAdded: candidate?.dateAdded,
        uploadedBy: candidate?.uploadedBy,
        vendorName: candidate?.vendorName,
        sourceMedium: candidate?.sourceMedium,
    };
});

export default function CandidateList() {
    return (
        <div className="candidate-list-wrapper">
            <div className="candidate-list-btns-wrapper">
                <Button className="candidate-list-btn" type="primary">
                    Client Name
                </Button>
                <Button className="candidate-list-btn" type="primary">
                    Skills
                </Button>
                <Button className="candidate-list-btn" type="primary">
                    Name
                </Button>
                <Button className="candidate-list-btn" type="primary">
                    Location
                </Button>
                <Button className="candidate-list-btn" type="primary">
                    Job ID
                </Button>
                <Button className="search-btn" type="primary">
                    Search
                </Button>
            </div>
            <div className="candidate-list-wrapper">
                {getCandidateList.length > 0 ? (
                    <Table
                        titleList={candidateDetailsListTable}
                        data={candidateDetailsData}
                    />
                ) : (
                    <Empty style={{ margin: "60px" }} />
                )}
            </div>
        </div>
    );
}
