import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Empty } from "antd";

import Table from "../Table";
import CandidateAddModal from "../CandidateAddModal";

import useCandidateStore from "../../store/candidateModal.store";
import useResumeStore from "../../store/resume.store";
import ResumeAnalysis from "./resumeanalysys";
import "./jdSummary.scss";

const summaryListTable = [
    "Number",
    "Name",
    "Email Id",
    "Mobile",
    "Resume Suitability",
    "Availability",
    "Notice Period (Negotiable Days)",
    "ECTC",
    "Screen Status",
    "Resume File",
    "Skill Matrix Suitability",
    "Assessment Suitablity",
];

const candidateDetailsListTable = [
    "Number",
    "Name",
    "Email Id",
    "Mobile",
    "Preferred Location",
    "Current Designation",
    "Notice Period (official days)",
    "Share to Client",
    "Notice Period (Negotiable days)",
    "LWD in Current Company",
    "Offer Joining Date",
    "Offer CTC",
    "Max Degree",
    "Current Company",
    "Current Location",
    "Referral",
    "Remarks",
    "Availability",
    "ECTC",
    "CCTC",
    "Date Added",
    "Uploaded By",
    "Vendor Name",
    "Source Medium",
];

export default function JDSummary() {
    const [currentView, setCurrentView] = useState("summary");

    const { value } = useParams();

    const { setCandidateModalOpen } = useCandidateStore();
    const { resumes, getResumesByJD, updateResume, downloadResumeByID } =
        useResumeStore();

    useEffect(() => {
        getResumesByJD(value);
    }, []);

    const handleDownloadResume = (resumeID) => {
        downloadResumeByID(value, resumeID);
    };

    const summaryData = resumes?.map((resumeDetail, idx) => {
        return {
            id: resumeDetail?.ID,
            name: resumeDetail?.name,
            email: resumeDetail?.email,
            phone: resumeDetail?.phone,
            resumeSuitability: resumeDetail?.resumeSuitability,
            availability: resumeDetail?.availability,
            noticePeriodNegotiable: resumeDetail?.noticePeriodNegotiable,
            ECTC: resumeDetail?.ECTC,
            screenStatus: resumeDetail?.screenStatus,
            downloadResume: (
                <div
                    style={{ color: "blue", fontWeight: "600" }}
                    onClick={() => handleDownloadResume(resumeDetail.ID)}
                >
                    Download Resume
                </div>
            ),
            skillMatrixSuitability: resumeDetail?.skillMatrixSuitability,
            assessmentSuitablity: resumeDetail?.assessmentSuitablity,
        };
    });

    const candidateDetailsData = resumes?.map((resumeDetail, idx) => {
        return {
            id: resumeDetail?.ID,
            name: resumeDetail?.name,
            email: resumeDetail?.email,
            phone: resumeDetail?.phone,
            preferredLocation: resumeDetail?.preferredLocation,
            currentDesignation: resumeDetail?.currentDesignation,
            noticePeriodOfficial: resumeDetail?.noticePeriodOfficial,
            share_to_client: "share to client",
            noticePeriodNegotiable: resumeDetail?.noticePeriodNegotiable,
            lwd: resumeDetail?.lwd,
            offerJoiningDate: resumeDetail?.offerJoiningDate,
            offerCTC: resumeDetail?.offerCTC,
            maxDegree: resumeDetail?.maxDegree,
            currentCompany: resumeDetail?.currentCompany,
            currentLocation: resumeDetail?.currentLocation,
            referral: resumeDetail?.referral,
            remarks: resumeDetail?.remarks,
            availability: resumeDetail?.availability,
            ECTC: resumeDetail?.ECTC,
            CCTC: resumeDetail?.CCTC,
            dateAdded: resumeDetail?.dateAdded,
            uploadedBy: resumeDetail?.uploadedBy,
            vendorName: resumeDetail?.vendorName,
            sourceMedium: resumeDetail?.sourceMedium,
        };
    });

    const handleEditField = (bodyKey, bodyValue, id) => {
        const filteredResume = resumes.filter((res) => res.ID === id);
        updateResume(value, id, { ...filteredResume[0], [bodyKey]: bodyValue });
    };

    return (
        <div className="jd-summary-wrapper">
            <div className="section-header">
                <h3 className="title">Resumes</h3>
                <Button
                    onClick={setCandidateModalOpen}
                    className="btn"
                    type="primary"
                >
                    Upload Resume
                </Button>
            </div>
            <div className="jd-summary-buttons-wrapper">
                <Button
                    className={`jd-summary-btn ${
                        currentView === "summary" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("summary")}
                >
                    Summary
                </Button>
                <Button
                    className={`jd-summary-btn ${
                        currentView === "candidate" && "jd-summary-btn-active"
                    }`}
andidate Details                    onClick={() => setCurrentView("candidate")}
                >
                    Candidate Details
                </Button>
                <Button className="jd-summary-btn"
                onClick={() => setCurrentView("resume_analysis")}>
                    Resume Analysis
                    </Button>
            </div>
            {resumes ? (
                <div className="jd-summary">
                    {currentView === "candidate" ? (
                        <Table
                            titleList={candidateDetailsListTable}
                            data={candidateDetailsData}
                            isEditField
                            editFieldHandler={handleEditField}
                        />
                    ) : currentView === "resume_analysis" ? (
                        <ResumeAnalysis />
                    ) : (
                        <Table
                            titleList={summaryListTable}
                            data={summaryData}
                            isEditField
                            editFieldHandler={handleEditField}
                        />
                    )}
                </div>
            ) : (
                <Empty style={{ margin: "60px" }} />
            )}

            <CandidateAddModal />
         
        </div>
    );
}
