import { create } from "zustand";

const useCandidateStore = create((set) => ({
    isCandidateModalOpen: false,
    setCandidateModalOpen: () => {
        set({ isCandidateModalOpen: true });
    },
    handleModalOk: () => {
        set({ isCandidateModalOpen: false });
    },
    handleModalCancel: () => {
        set({ isCandidateModalOpen: false });
    },
    handleResumeUpload: (value) => {
        console.log(value);
    },
}));

export default useCandidateStore;
