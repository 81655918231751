import React, { useState } from "react";
import {
    Button,
    Modal,
    Form,
    Input,
    Select,
    DatePicker,
    Upload,
    message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { useParams } from "react-router-dom";

import useCandidateStore from "../../store/candidateModal.store";
import useResumeStore from "../../store/resume.store";

import "./candidateAddModal.scss";

const defaultInput = {
    name: "",

};

export default function CandidateAddModal() {
    const [formInput, setFormInput] = useState(defaultInput);

    const { value } = useParams();

    const { getResumesByJD } = useResumeStore();

    const { isCandidateModalOpen, handleModalOk, handleModalCancel } =
        useCandidateStore();

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const props = {
        name: "pdf",
        action: `https://api.qhrflow.com/users/${userDetails.ID}/jds/${value}/resumes`,
        maxCount: 1,
        data: {
            name: formInput.name,

        },
        beforeUpload() {
            const { name } = formInput;

            if (name === "") {
                message.error(`Please Enter the candidate details`);
                return false;
            }
        },
        onChange(info) {
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === "done") {
                message.success(`${info.file.name} file uploaded successfully`);
                getResumesByJD(value);
                handleModalOk();
            } else if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
                handleModalOk();
            }
        },
    };

    const handleFormInput = (event) => {
        setFormInput((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.value,
        }));
    };

    return (
        <Modal
            title="Resume Add Modal"
            open={isCandidateModalOpen}
            footer={null}
            onCancel={handleModalCancel}
        >
            <Form
                labelCol={{ flex: "150px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input candidate name!",
                        },
                    ]}
                >
                    <Input
                        name="name"
                        value={formInput.name}
                        onChange={handleFormInput}
                    />
                </Form.Item>


                <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Form>
        </Modal>
    );
}
