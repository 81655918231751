const initialstate = {
  jdData: [],

};

export const Jdsreducer = (state = initialstate, action) => {
  switch (action.type) {
    case "JD_LIST":
      console.log("JD_LIST reducer");
      return {
        ...state,

        jdData: action.payload,
      };

    default:
      return state;
  }
};
