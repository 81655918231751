import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import "./resumes.css";
import { Field, reduxForm } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../../actions";

const renderFileInput = ({ input, label }) => (
  <div className="form-group">
    <label>{label}</label>
    <input type="file" className="form-control-file" {...input} />
  </div>
);

function Resumetable(props) {

  const owner_id = useSelector((state) => state.authReducer.userID);

  const { id } = useParams();
  const { handleSubmit } = props;
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const [resume, setResume] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactnumber, setContactNumber] = useState("");

  const handleResumeChange = (event) => {
    console.log("Resume",event)
    setResume(event.target.files[0]);
    console.log(resume)
  };
  useEffect(() => {
    dispatch(actions.GetjdResumes(id))
  }, []);


  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleContactChange = (event) => {
    setContactNumber(event.target.value);
  };
  const handleUpload = () => {
    const formData = new FormData();
    formData.append("pdf", resume);
    formData.append("name", name);
    formData.append("email",email)
    formData.append("phone",contactnumber)
    console.log(formData);
    
    dispatch(actions.uploadResume(owner_id, id,formData));
    // Now you can use formData to send the data to the server using an API call
    // For example, using axios:
    // axios.post('/upload', formData).then(response => console.log(response));
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const navigate = useNavigate();
  const handleResumeOptionClick = (option) => {
    navigate(`${option}`);
    // Perform API call here with the selected option
  };
  
  return (
    <div className="resumeContainer">
      <div className="resumeNav d-flex">
        <div>
          {/* <button
            className="firstButton"
            onClick={() => handleResumeOptionClick("summary")}
          >
            Summary
          </button> */}
          <button onClick={() => handleResumeOptionClick("Candidatedetails")}>
            Candidate Details
          </button>
          <button onClick={() => handleResumeOptionClick("ResumeAnalysis")}>
            Resume Analysis
          </button>
        
        </div>
        <div>
          <button onClick={toggleModal}>Upload Resume</button>
        </div>
      </div>

      <div>
        <Outlet />
      </div>

      {showModal && (
        <div
          className="modal fade show"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Upload Resume
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={toggleModal}
                ></button>
              </div>
              <div className="modal-body">
                <div>
                  <div>
                    <label>
                      Name:
                      <input
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Email:
                      <input
                        type="text"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      contactnumber:
                      <input
                        type="text"
                        value={contactnumber}
                        onChange={handleContactChange}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Resume:
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={handleResumeChange}
                      />
                    </label>
                  </div>
                  <button onClick={handleUpload}>Upload</button>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={toggleModal}
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

var FileInputForm = reduxForm({
  form: "uploadResume", // A unique identifier for this form
})(Resumetable);

export default FileInputForm;
