import { create } from "zustand";

import axios from "../helper/axios";

var userData = JSON.parse(localStorage.getItem("userDetails"));

const useResumeStore = create((set) => ({
    resumes: [],
    resumeStats: [],
    resumeAnalysysData : {},
            
            

    downloadResumeByID: async (jdID,resumeID) => {
        try {
            userData = JSON.parse(localStorage.getItem("userDetails"));
            const response = await axios.get(
                `/users/${userData.ID}/jds/${jdID}/resumes/${resumeID}`,
                {
                    responseType : "blob"
                }
            );

            const blob = new Blob([response.data]);

            // Create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
    
            // Specify the filename for the downloaded file
            link.setAttribute('download', 'resume.pdf');
    
            // Append the anchor element to the body
            document.body.appendChild(link);
    
            // Trigger the download
            link.click();
    
            // Clean up
            link.parentNode.removeChild(link);
            console.log(response);
        } catch (err) {
            console.error(err);
        }
    },
    getResumesByJD: async (id) => {
        try {
            const response = await axios.get(`/resumes/jds/${id}`);

            console.log(response);

            set({ resumes: response?.data });
        } catch (err) {
            console.error(err);
        }
    },
    getAllResumes: async () => {
        try {
            const response = await axios.get(
                `/resumes/jds/6560a6d95f67bbbedb24ae5a/users/${userData.ID}`
            );

            console.log(response);

            set({ resumes: response?.data });
        } catch (err) {
            console.error(err);
        }
    },
    updateResume: async (jdId, resumeId, body) => {
        try {
            const formData = new FormData();

            for (let key in body) {
                formData.append(key, body[key]);
            }

            await axios.put(
                `/users/${userData.ID}/jds/${jdId}/resumes/${resumeId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data", // Important for handling form data
                    },
                }
            );
        } catch (err) {
            console.error(err);
        }
    },
    getResumeStats: async () => {
        try {
            const response = await axios.get(
                `/users/${userData.ID}/resumestats`
            );

            set({ resumeStats: response?.data });
        } catch (err) {
            console.error(err);
        }
    },


    getResumeSkill: async (jdid,resumeId) => {
        try {
            userData = JSON.parse(localStorage.getItem("userDetails"));
            console.log(userData);
            const response = await axios.get(
                `/users/${userData.ID}/jds/${jdid}/resumes/${resumeId}/skillmatch`
            );

            set({ resumeAnalysysData: response?.data });
        } catch (err) {
            console.error(err);
        }
    },
}));



export default useResumeStore;
