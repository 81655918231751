import React from 'react';


function Profile() {
    return ( 
        <div>
            <h1>
                user profile
            </h1>
        </div>
     );
}

export default Profile;